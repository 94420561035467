import http from '~/http-common'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import type { ReservedEvent } from '~~/types/reservedEvent'
import type { ReservedEventRes } from '~~/types/reservedEventRes'
import { ApiPath } from '~/enum/apiPath'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const RESERVED_EVENT_STORE_KEY = 'ReservedEventStore'

export const useReservedEventStore = defineStore(RESERVED_EVENT_STORE_KEY, () => {

  /**
   * 予約済みイベント一覧
   */
  const reservedEventList = ref<ReservedEvent[]>([])

  /**
   * 予約済みイベント一覧を取得
   */
  const getReservedEventList = () => {
    return reservedEventList.value
  }

  /**
   * イベントIDから予約済みイベントを取得
   */
  const getReservedEventByEventId = (eventId: number) => {
    return reservedEventList.value.find((e) => e.applyId = eventId)
  }

  const resetReservedEvents = () => {
    reservedEventList.value = []
  }

  const getReservedEventDatas = async() => {
    // APIから予約済みイベントの一覧を取得
    const path = ApiPath.RESERVED_EVENT_MODULE_GET_RESERVED_EVENT_DATAS
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }

    const eventsList:ReservedEvent[] = []

    // 未実施のイベント一覧を取得
    const queryStrings1: string = makeQueryStrings({flg: 1})
    const response1: ReservedEventRes = await http.get(path + queryStrings1, { headers: headers })
    if (response1.data.code == 0) {
      eventsList.push(...response1.data.stuList)
    }
    // 実施済みのイベント一覧を取得
    const queryStrings2: string = makeQueryStrings({flg: 2})
    const response2: ReservedEventRes = await http.get(path + queryStrings2, { headers: headers })
    if (response2.data.code == 0) {
      eventsList.push(...response2.data.stuList)
    }

    // storeに設定
    reservedEventList.value = eventsList
  }

  return {
    reservedEventList,
    getReservedEventList,
    getReservedEventByEventId,
    resetReservedEvents,
    getReservedEventDatas,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})
